// stylelint-disable no-duplicate-selectors, selector-max-combinators, selector-max-compound-selectors, selector-max-type, selector-no-qualifying-type

//
// Automatically style Markdown-based tables like a Bootstrap `.table`.
//

body {
  font-family: $font-family-base;
}

.bd-content {
  order: 1;
  padding-top: 48px;
  padding-right: 92px;
  padding-bottom: 48px;
  padding-left: 92px;
  font-family: $font-family-base;

  // Hack the sticky header
  > h2[id],
  > h3[id],
  > h4[id] {
    pointer-events: none;

    &::before {
      display: block;
      height: 6rem;
      margin-top: -6rem;
      content: "";
    }
  }

  > table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;

    @include media-breakpoint-down(md) {
      display: block;
      overflow-x: auto;

      &.table-bordered {
        border: 0;
      }
    }

    // Cells
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          padding: $table-cell-padding;
          vertical-align: top;
          border: 1px solid $table-border-color;

          > p:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    // Prevent breaking of code (e.g., Grunt tasks list)
    td:first-child > code {
      white-space: nowrap;
    }
  }
}

.bd-content-title {
  display: block;
  pointer-events: auto;
}

//
// Docs sections
//

.bd-content {
  > h2:not(:first-child) {
    margin-top: 3rem;
  }

  > h3 {
    margin-top: 1.5rem;
  }

  > ul li,
  > ol li {
    margin-bottom: .25rem;
  }

  @include media-breakpoint-up(lg) {
    > ul,
    > ol {
      max-width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin-bottom: 1rem;
  }
}

.bd-title {
  background-color: $bd-cloud;
}

.bd-lead {
  @include font-size(1rem);
  font-weight: 300;
  @include media-breakpoint-up(lg) {
    max-width: 60%;
  }
}

.bd-text-purple { color: $bd-purple; }
.bd-text-purple-bright { color: $bd-purple-bright; }

.bd-color-example {
  min-height: 100%;
}

.bd-block-center {
  margin: 0 auto;
}
