// Graphik as brand Mekari fontfamily
$font-family: "Graphik";
$fonts: (
  300: "Regular",
  400: "Regular",
  500: "Medium",
  700: "Medium",
  900: "Medium"
);

$font-path: #{"../fonts/Graphik-"} !default;

@each $font-weight, $font-type in $fonts {
  $src: #{$font-path}#{$font-type};
  @font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: #{$font-weight};
    src: local($font-family);
    src: url("#{$src}.otf") format("opentype");
  }
}
