//
// Main navbar
//

.bd-navbar {
  min-height: 4rem;
  background-color: $bd-white;

  @include media-breakpoint-down(md) {
    padding-right: .5rem;
    padding-left: .5rem;

    .navbar-nav-scroll {
      max-width: 100%;
      height: 2.5rem;
      margin-top: .25rem;
      overflow: hidden;

      .navbar-nav {
        padding-bottom: 2rem;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
    }
  }

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      position: sticky;
      top: 0;
      z-index: 1071; // over everything in bootstrap
    }
  }

  .navbar-nav {
    .nav-link {
      padding: .25rem 0;
      margin-right: 1rem;
      margin-left: 1rem;
      font-weight: $font-weight-light;
      color: $bd-dark;

      &.active,
      &:hover {
        background-color: transparent;
        border-bottom: solid $bd-dark 2px;
      }

      &.active {
        font-weight: 600;
      }
    }
  }

  .navbar-nav-svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
  }

  .dropdown-menu {
    @include font-size(.875rem);
  }

  .dropdown-item.active {
    font-weight: 600;
    color: $gray-900;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: .4rem .6rem;
    background-size: .75rem .75rem;
  }
}

.bd-nav-footer {
  padding-top: 48px;
  padding-right: 92px;
  padding-bottom: 48px;
  padding-left: 92px;
}

